import React from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'

type SideMenuItemProps = {
  icon: React.ReactElement
  name: string
  href: string
  className?: string
}

export function SideMenuItem({ icon, name, href, className }: SideMenuItemProps) {
  const { t } = useTranslation()

  return (
    <Link href={href} className={`flex items-center text-darkblue ${className || ''}`}>
      <div className="mr-3 flex w-6 justify-center">{icon}</div>
      {t(name)}
    </Link>
  )
}

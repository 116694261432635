export const getDataTestAttributes = (
  isVisible: boolean,
  dataTestPostfix?: string,
): Record<string, string> => {
  return {
    ...(isVisible
      ? {
          ['data-test-id']: `${dataTestPostfix}`,
        }
      : {}),
  }
}

import { AvailableSectionsInterface } from '../types/available-sections-interface'

export function isShown(data: AvailableSectionsInterface, name: string): boolean {
  switch (name) {
    case 'header.nav.communities': {
      return data.communities
    }
    case 'header.nav.courses': {
      return data.courses
    }
    default: {
      return true
    }
  }
}

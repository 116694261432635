import React from 'react'
import { useTranslation } from 'next-i18next'
import EmptyFolderIcon from 'shared/icons/empty-folder-icon'

const NotificationEmptyList = () => {
  const { t } = useTranslation()
  return (
    <div className="flex w-full flex-col items-center justify-center gap-3 rounded-b-lg bg-white py-20">
      <EmptyFolderIcon height={100} width={100} />
      <div className={`flex flex-col items-center`}>
        <span className={'text-base font-medium text-darkblue'}>
          {t('profile.notifications.empty_list.title')}
        </span>
        <span className={'text-base text-darkblue'}>
          {t('profile.notifications.empty_list.description')}
        </span>
      </div>
    </div>
  )
}

export default NotificationEmptyList

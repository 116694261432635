import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useShowChangePasswordPage } from 'shared/hooks/use-show-change-password-page'
import { addPublicCustomClassNamePrefix } from 'modules/course/utils/add-class-prefix'
import { UserRolesEnum } from '../enums/user-roles-enum'
import useUser, { isAuthorizedUser } from '../hooks/use-user'
import ChevronIcon from '../icons/chevron-icon'

const hrefArray = ['/dashboard/profile/user-settings', '/dashboard/profile/change-password']

function Avatar({
  popupClassName,
  linkClassName,
}: {
  popupClassName?: string
  linkClassName?: string
}) {
  const { user, loading } = useUser()
  const { t } = useTranslation()
  const [pathName, setPathName] = useState('')
  const { showChangePasswordPage } = useShowChangePasswordPage()

  useEffect(() => {
    setPathName(window.location.pathname)
  }, [])

  if (user && !isAuthorizedUser(user)) {
    return null
  }

  if (loading || !user || !isAuthorizedUser(user)) {
    return (
      <div
        className={`h-10 w-10 animate-pulse rounded-full bg-gray ${addPublicCustomClassNamePrefix(
          'avatar-skeleton',
        )}`}
      />
    )
  }

  const hasCustomerRole = user.roles.includes(UserRolesEnum.Customer)
  const hasAffiliateRole = user.roles.includes(UserRolesEnum.Affiliate)

  const Link = ({ href, children }: React.PropsWithChildren<{ href: string }>) => (
    <a
      href={href}
      className={`cursor-pointer px-7 py-1.5 hover:bg-lightblue ${linkClassName || ''}`}
    >
      {children}
    </a>
  )

  return (
    <div className={`h-full shrink-0 ${addPublicCustomClassNamePrefix('avatar')}`}>
      <div className="group relative h-full">
        <button
          className={`flex h-full items-center gap-2.5 border-b-4 focus:outline-none focus-visible:border-blue ${
            hrefArray.includes(pathName) ? 'border-blue' : 'border-transparent'
          }`}
        >
          <img
            className="h-10 w-10 rounded-full object-cover"
            src={user.avatarUrl}
            alt={`${user.firstName} ${user.lastName}`}
          />
          <ChevronIcon />
        </button>
        <div
          className={`bg-grey-200 invisible absolute -left-[65px] top-[65px] z-50 min-w-max opacity-0 transition transition-all duration-300 group-hover:visible group-hover:opacity-100`}
        >
          <div
            className={`absolute -top-[12px] right-[85px] border-[6px] border-b-white border-l-transparent border-r-transparent border-t-transparent`}
          />
          <div
            className={`flex flex-col rounded-lg bg-white py-4 text-sm text-gray-300 shadow-lg ${
              popupClassName || ''
            } `}
          >
            {!user.workspaceOwner && (
              <Link href="/profile/user-settings">{t('user.profile.menu.settings')}</Link>
            )}
            {showChangePasswordPage && (
              <Link href="/profile/change-password">{t('user.profile.menu.change_password')}</Link>
            )}
            <hr className="mx-4 px-4 text-gray" />
            {user.impersonator && (
              <Link href="/dashboard/admin/management/user/list?pretend_this_user=_exit">
                {t('header.exit_impersonation')}
              </Link>
            )}
            <Link href="/dashboard/logout">{t('user.profile.menu.logout')}</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Avatar

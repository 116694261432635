import React, { AnchorHTMLAttributes, forwardRef, useCallback } from 'react'
import Link, { LinkProps } from 'next/link'

export type LinkType = React.PropsWithChildren<LinkProps> &
  AnchorHTMLAttributes<HTMLAnchorElement> & { disabled?: boolean }

const LinkWithoutPrefetch = forwardRef<HTMLAnchorElement, LinkType>(
  ({ disabled, ...props }, ref) => {
    const handleLinkClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
      e => {
        if (disabled) e.preventDefault()
      },
      [disabled],
    )

    return <Link {...props} onClick={handleLinkClick} prefetch={false} ref={ref} />
  },
)
LinkWithoutPrefetch.displayName = 'LinkWithoutPrefetch'
export default LinkWithoutPrefetch

export interface GetFilterQueryString<T> {
  filter: T
}

export const getFilterQueryString = <
  T extends Record<string, string | number | boolean | null | undefined>,
>({
  filter,
}: GetFilterQueryString<T>) =>
  filter
    ? Object.entries(filter).reduce(
        (res, [key, value]) =>
          `${res}${value ? `&filter[${key}]=${encodeURIComponent(value)}` : ''}`,
        '',
      )
    : ''

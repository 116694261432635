import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import React, { Fragment } from 'react'

export interface BaseModalProps {
  className?: string
  afterLeave?: () => void
  afterEnter?: () => void
  opened: boolean
  onClose: () => void
  small?: boolean
  isFetching?: boolean
}

function BaseModal({
  className,
  afterLeave,
  afterEnter,
  onClose,
  children,
  small,
  isFetching,
  opened,
}: React.PropsWithChildren<BaseModalProps>) {
  return (
    <Transition show={opened} as={Fragment} afterEnter={afterEnter}>
      <Dialog className="relative z-20" open={opened} onClose={!isFetching ? onClose : () => {}}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-darkblue/40 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full w-full items-end justify-center text-center sm:items-center sm:p-0 lg:p-4">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              afterLeave={afterLeave}
            >
              <DialogPanel
                className={`relative w-full ${
                  small ? 'max-w-2xl' : 'max-w-4xl'
                } transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ${
                  className || ''
                }`}
              >
                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default BaseModal

import { COMMUNITY_API_PREFIX } from 'shared/api/constants/api-constants'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { CommunityInterface } from '../types/community-interface'

export const useJoinCommunity = () => {
  const { fetcher, isFetching } = useApiWrapperWithErrorValidation({
    method: RequestMethodsEnum.get,
    // the public community has an own login form, and we need to make custom redirection
    unauthorizedResponseHandler: e => {
      throw e
    },
  })
  return {
    joinCommunity: async (communityPath: CommunityInterface['path']) => {
      await fetcher(`${COMMUNITY_API_PREFIX}/${communityPath}/join`)
    },
    isFetching: isFetching,
  }
}

import { useEffect, useRef, useState } from 'react'
import Router, { useRouter } from 'next/router'
import { usePaginationQuery } from 'shared/route-query/hooks/use-pagination-query'
import { getFilterQuery } from 'shared/route-query/utils/get-filter-query'
import { getPaginationQuery } from 'shared/route-query/utils/get-pagination-query'
import { setFilterQuery } from 'shared/route-query/utils/set-filter-query'
import { setPaginationQuery } from 'shared/route-query/utils/set-pagination-query'
import { LimitType, OrderEnum, PaginationInterface } from 'shared/types/swr-pagination-interface'
import { limitObj_10_25_50 } from '../constants/pagination-limit-array'

const paginationKeys = ['order', 'limit', 'startFromId']

export const useFilterPagination = <T>({
  defaultFilter,
  isStaticUrl = false,
  initLimit = limitObj_10_25_50.short,
}: {
  defaultFilter: T
  isStaticUrl?: boolean
  initLimit?: LimitType
}) => {
  const { query, isReady } = useRouter()

  const { limit: limitFromRouter, startFromId, order } = usePaginationQuery<T>()

  const queryRef = useRef(query)

  const [limit, setLimit] = useState<LimitType>(limitFromRouter || initLimit)

  const [pagination, setPagination] = useState<PaginationInterface>({
    startFromId: startFromId,
    order: OrderEnum.next,
    staticUrl: isStaticUrl,
  })

  const [filter, setFilter] = useState<T>(defaultFilter)

  const filterKeys = useRef(Object.keys(filter) as Array<keyof T>)

  useEffect(() => {
    filterKeys.current = Object.keys(filter) as Array<keyof T>
  }, [filter])

  useEffect(() => {
    queryRef.current = query
  }, [query])

  useEffect(() => {
    if (!isReady) return

    setPaginationQuery({ setPagination, setLimit, order, limit: limitFromRouter, startFromId })

    setFilterQuery({ setFilter, queryRef, filterKeys })
  }, [limitFromRouter, order, startFromId, isReady])

  useEffect(() => {
    if (isStaticUrl || !isReady) return

    const paginationQuery = getPaginationQuery({ pagination, limit, initLimit })
    const filterQuery = getFilterQuery({ filter, defaultFilter, filterKeys })

    const baseQueryKey = (Object.keys(queryRef.current) as Array<keyof T & string>).find(
      el => !paginationKeys.includes(el) && !filterKeys.current.includes(el),
    )
    const baseQuery = baseQueryKey ? { [baseQueryKey]: queryRef.current[baseQueryKey] } : {}

    const timeout = setTimeout(() => {
      Router.replace(
        {
          query: {
            ...baseQuery,
            ...paginationQuery,
            ...filterQuery,
          },
        },
        undefined,
        { shallow: true },
      )
    })

    return function cleanUp() {
      clearTimeout(timeout)
    }
  }, [isStaticUrl, limit, pagination, isReady, filter, defaultFilter])

  return { limit, setLimit, pagination, setPagination, filter, setFilter }
}

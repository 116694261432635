import axios, { AxiosRequestConfig } from 'axios'
import { AccessDenied } from '../errors/access-denied'
import { BadRequest } from '../errors/bad-request'
import { ConflictError } from '../errors/conflict-error'
import { InternalError } from '../errors/internal-error'
import { InvalidTokenError, invalidTokenErrorMessages } from '../errors/invalid-token-error'
import { NetworkError } from '../errors/network-error'
import { NotFound } from '../errors/not-found'
import { PayloadTooLarge } from '../errors/payload-too-large'
import { UnauthorizedResponse } from '../errors/unauthorized-response'
import { UnprocessableContentError } from '../errors/unprocessable-content-error'
import { regenerateJWT } from './regenerate-jwt'

export const createHttpClient = (baseURL?: string) => {
  const httpClient = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
  })

  httpClient.interceptors.response.use(
    response => response,
    error => {
      if (error.response) {
        if (error.response.status === 400) {
          throw new BadRequest(error.response.data.errors, error.response)
        } else if (error.response.status === 401) {
          if (invalidTokenErrorMessages.includes(error.response.data.message)) {
            throw new InvalidTokenError()
          } else if (error.response.data.location) {
            throw new UnauthorizedResponse(error.response.data.location)
          } else {
            ;(window as any).Rollbar.error('Unauthorized response', error.response.data)
          }
        } else if (error.response.status === 403) {
          throw new AccessDenied(error.response)
        } else if (error.response.status === 404) {
          throw new NotFound(error.response)
        } else if (error.response.status === 409) {
          throw new ConflictError(error.response.data)
        } else if (error.response.status === 413) {
          throw new PayloadTooLarge()
        } else if (error.response.status === 422) {
          throw new UnprocessableContentError()
        } else if (error.response.status === 500 || error.response.status === 502) {
          throw new InternalError()
        } else if (error.code === 'ERR_NETWORK') {
          throw new NetworkError()
        }
      }
    },
  )

  let isJWTRegenerated = false

  const fetcher = <T = any>(url: string, config?: AxiosRequestConfig): Promise<T> =>
    httpClient
      .get(url, config)
      .then(res => res.data)
      .catch(async e => {
        if (e instanceof UnauthorizedResponse) {
          window.location.assign(e.location)
        } else if (e instanceof InvalidTokenError) {
          if (isJWTRegenerated) {
            ;(window as any).Rollbar.error('Invalid token error', e)
          } else {
            try {
              // NOTE: Generating a new JWT
              await regenerateJWT()
              isJWTRegenerated = true
              // NOTE: repeat the same request
              return fetcher(url, config)
            } catch (error) {
              if (error instanceof UnauthorizedResponse) {
                window.location.assign(error.location)
              }
            }
          }
        } else {
          throw e
        }
      })

  return { httpClient, fetcher }
}

export const { httpClient } = createHttpClient()

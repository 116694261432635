import React from 'react'

function CommunityIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 100 100" width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="CurrentColor">
        <path d="M25.119 77.143v4.524c0 .6.238 1.176.663 1.599.423.425 1 .663 1.599.663h45.238c.6 0 1.176-.238 1.6-.663.424-.423.662-1 .662-1.6v-4.523a24.881 24.881 0 0 0-49.762 0zM4.762 83.929h16.222a6.756 6.756 0 0 1-.389-2.262v-4.524c0-5.166 1.36-10.192 3.88-14.599a15.768 15.768 0 0 0-6.142-1.234C9.59 61.31 2.5 68.398 2.5 77.143v4.524a2.263 2.263 0 0 0 2.262 2.262zM81.667 61.31c-2.179 0-4.253.438-6.141 1.234a29.406 29.406 0 0 1 3.879 14.599v4.524c0 .778-.134 1.542-.39 2.262h16.223a2.263 2.263 0 0 0 2.262-2.262v-4.524c0-8.745-7.089-15.833-15.833-15.833z" />
        <circle cx="18.333" cy="45.476" transform="rotate(-44.44 18.347 45.5)" r="11.309" />
        <circle cx="81.667" cy="45.476" transform="rotate(-44.44 81.716 45.495)" r="11.31" />
        <circle cx="50" cy="31.905" transform="rotate(-9.15 49.958 31.885)" r="15.833" />
      </g>
    </svg>
  )
}

export default CommunityIcon

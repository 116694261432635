import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { httpClient } from '../../../shared/api/http-client'
import FormInput from '../../../shared/components/form-input'
import Modal from '../../../shared/components/modal'
import PrimaryButton from '../../../shared/components/primary-button'
import { BadRequest } from '../../../shared/errors/bad-request'
import { InternalError } from '../../../shared/errors/internal-error'
import PlusIcon from '../../../shared/icons/plus-icon'
import { generatePathFromName } from '../hooks/path-utils'
import { useCommunityWithTopics } from '../hooks/use-community-with-topics'
import { TopicInterface } from '../types/topic-interface'

type UpdateTopicProps = {
  topic: TopicInterface
  onCloseModal: () => void
  onUpdateSuccess: () => void
}

function UpdateTopic({ topic, onCloseModal, onUpdateSuccess }: UpdateTopicProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const { topicPath, communityPath } = router.query
  const [opened, setOpened] = useState(true)
  const [name, setName] = useState(topic.name)
  const [nameError, setNameError] = useState('')
  const [path, setPath] = useState(topic.path)
  const [pathError, setPathError] = useState('')
  const [error, setError] = useState('')
  const [isButtonActive, setIsButtonActive] = useState(false)

  const { data: community, mutate } = useCommunityWithTopics()

  function changeName(e: React.ChangeEvent<HTMLInputElement>) {
    setName(e.target.value)
    setNameError('')
  }

  function changePath(e: React.ChangeEvent<HTMLInputElement>) {
    setPath(e.target.value)
    setPathError('')
  }

  function convertNameToPath(e: React.FocusEvent<HTMLInputElement>) {
    if (!e.target.value) {
      setPath(generatePathFromName(name))
      setPathError('')
    }
  }

  const clearForm = () => {
    setName('')
    setPath('')
  }

  const handleSendForm = async () => {
    if (!community) {
      return
    }
    try {
      const { data } = await httpClient.patch<TopicInterface>(`/api/community/topic/${topic.id}`, {
        name,
        path,
      })
      await mutate(
        {
          ...community,
          topics: community.topics.map(t => (t.id === data.id ? data : t)),
        },
        true,
      )
      onUpdateSuccess()
      setOpened(false)
      clearForm()
      if (topic.path !== path) {
        // we change current topicPath
        if (topicPath === topic.path) {
          await router.replace(`/${communityPath}/${path}`)
        }
      }
    } catch (e) {
      if (e instanceof BadRequest) {
        if (e.errors?.fields?.path) {
          setPathError(e.errors?.fields?.path.join('\n'))
        }
        if (e.errors?.fields?.name) {
          setNameError(e.errors?.fields?.name.join('\n'))
        }
        if (e.errors.common && e.errors.common.length > 0) {
          setError(e.errors.common.join('\n'))
        }
      } else if (e instanceof InternalError) {
        setError(t('core.error.internal_error_message'))
      }
    }
  }

  function closeModal() {
    setOpened(false)
    onCloseModal()
  }

  useEffect(() => {
    if (name !== topic.name || path !== topic.path) {
      setIsButtonActive(true)
    } else {
      setIsButtonActive(false)
    }
  }, [name, path])

  return (
    <>
      <Modal opened={opened} onClose={closeModal} title={t('settings.update_topic.modal.title')}>
        <div className="mb-6">
          <FormInput
            value={name}
            label={t('settings.form.name.label')}
            onChange={changeName}
            maxLength={256}
            error={nameError}
          />
        </div>
        <div className="mb-6">
          <FormInput
            value={path}
            label={t('settings.form.path.label')}
            onChange={changePath}
            onFocus={convertNameToPath}
            maxLength={32}
            error={pathError}
          />
        </div>
        <PrimaryButton
          className={`ml-auto ${
            isButtonActive ? 'bg-darkblue' : 'pointer-events-none bg-bluegray'
          }`}
          onClick={handleSendForm}
        >
          <PlusIcon />
          {t('settings.update_topic.modal.title')}
        </PrimaryButton>
        {error && <p className="mt-2 text-sm text-red">{error}</p>}
      </Modal>
    </>
  )
}

export default UpdateTopic

import { twMerge } from 'tailwind-merge'

export interface UnreadIndicatorProps {
  className?: string
  iconClassName?: string
}

const UnreadIndicator = ({ className, iconClassName }: UnreadIndicatorProps) => (
  <div className={twMerge('flex items-center rounded-full p-1 text-xs', className)}>
    <div className={twMerge('h-[5px] w-[5px] rounded-full bg-blue', iconClassName)} />
  </div>
)

export default UnreadIndicator

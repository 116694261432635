import { Float } from '@headlessui-float/react'
import { Menu, MenuButton, MenuItems } from '@headlessui/react'
import React from 'react'
import { useTranslation } from 'next-i18next'
import { LocaleEnum, localeNames } from '../../../shared/enums/locale-enum'
import { useLanguageSwitcher } from '../../../shared/hooks/use-language-switcher'
import useUser from '../../../shared/hooks/use-user'
import ChevronIcon from '../../../shared/icons/chevron-icon'
import { classWithModifiers } from 'shared/utils/className-with-modifiers'
import { addPublicCustomClassNamePrefix } from 'modules/course/utils/add-class-prefix'

type LanguageSwitcherProps = {
  className?: string
  listClassName?: string
  listItemClassName?: string
}

function getCalculatedLanguage(language: string) {
  let readableLanguage
  switch (language) {
    case LocaleEnum.JAPANESE:
      readableLanguage = 'jp'
      break
    default:
      readableLanguage = language
  }

  return readableLanguage.toUpperCase()
}

function LanguageSwitcher({ className, listClassName, listItemClassName }: LanguageSwitcherProps) {
  const { i18n } = useTranslation()
  const { user, loading } = useUser()

  const { handleChangeLanguage } = useLanguageSwitcher()

  return (
    <div
      className={`group relative h-full text-white/90 ${className || ''} ${classWithModifiers(
        addPublicCustomClassNamePrefix('language-switcher'),
        {
          ['loading']: loading,
        },
      )}`}
    >
      {loading ? (
        <div className={`mt-5 h-6 w-6 animate-pulse rounded-sm bg-gray`} />
      ) : (
        user && (
          <div className="flex h-full items-center justify-around gap-2.5 border-b-4 border-transparent focus:outline-none focus-visible:border-blue">
            <div className="w-5 text-sm font-bold">
              {i18n && i18n.language ? getCalculatedLanguage(i18n.language) : ''}
            </div>
            <ChevronIcon />
          </div>
        )
      )}
      <div className="bg-grey-200 customScroll invisible absolute -left-20 top-16 z-50 max-h-[400px] overflow-hidden overflow-y-auto opacity-0 transition transition-all group-hover:visible group-hover:opacity-100">
        <div className="absolute -top-[12px] right-[35px] border-[6px] border-b-white border-l-transparent border-r-transparent border-t-transparent" />
        <div
          className={`flex flex-col rounded-lg bg-white py-4 text-sm shadow-lg ${
            listClassName || ''
          }`}
        >
          <ul>
            {Object.keys(localeNames)
              .filter(dashboardLocale => dashboardLocale !== i18n.language)
              .map(dashboardLocale => (
                <li key={dashboardLocale}>
                  <button
                    className={`w-full cursor-pointer px-7 py-1 text-start text-gray-300 hover:bg-lightblue focus:outline-none focus-visible:text-blue ${
                      listItemClassName || ''
                    }`}
                  >
                    <p onClick={() => handleChangeLanguage(dashboardLocale as LocaleEnum)}>
                      {localeNames[dashboardLocale as LocaleEnum]}
                    </p>
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export function MobileLanguageSwitcher({
  className,
  listClassName,
  listItemClassName,
}: LanguageSwitcherProps) {
  const { i18n } = useTranslation()
  const { user } = useUser()

  const { handleChangeLanguage } = useLanguageSwitcher()

  if (!i18n) {
    return <div>loading...</div>
  }

  return (
    <Menu>
      <Float
        as="div"
        className={`relative ${className || ''}`}
        placement="top-end"
        flip
        offset={-25}
        enter="transition duration-300 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-175 ease-in"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <MenuButton className="relative flex items-center justify-between gap-2 px-4 py-2">
          <span className="text-darklue flex-1 text-start capitalize">{user?.dashboardLocale}</span>
          <ChevronIcon className="h-3 w-3 rotate-180" aria-hidden="true" />
        </MenuButton>

        <MenuItems
          className={`divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
            listClassName || ''
          }`}
          static
        >
          <div className="py-1">
            {Object.keys(localeNames)
              .filter(dashboardLocale => dashboardLocale !== i18n.language)
              .map(dashboardLocale => (
                <MenuButton
                  className={`my-2 px-4 py-1 text-sm text-darkblue ${listItemClassName || ''}`}
                  onClick={() => handleChangeLanguage(dashboardLocale as LocaleEnum)}
                  key={dashboardLocale}
                >
                  {localeNames[dashboardLocale as LocaleEnum]}
                </MenuButton>
              ))}
            {Object.keys(localeNames).includes(i18n.language) && (
              <MenuButton
                className={`my-2 flex w-full items-center px-4 py-1 text-sm text-blue ${
                  listItemClassName || ''
                }`}
                onClick={() => handleChangeLanguage(i18n.language as LocaleEnum)}
              >
                {localeNames[i18n.language as LocaleEnum]}
                <ChevronIcon className="ml-3 h-3 w-3 rotate-180" aria-hidden="true" />
              </MenuButton>
            )}
          </div>
        </MenuItems>
      </Float>
    </Menu>
  )
}

export default LanguageSwitcher

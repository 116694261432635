import React, { FC } from 'react'
import { twJoin, twMerge } from 'tailwind-merge'
import ButtonLinkWrapper from 'shared/components/button/components/button-link-wrapper'
import {
  BASE_CLASS_NAME,
  DASHBOARD_FONT_CLASS_NAME,
  PUBLIC_FONT_CLASS_NAME,
} from 'shared/components/button/constants/base-class-name'
import { ButtonProps, ButtonType } from 'shared/components/button/types/button-interface'
import { isLink } from 'shared/components/button/utils/has-href'
import { Loader, LoaderProps } from 'shared/components/loader'
import { getDataTestAttributes } from 'shared/utils/get-data-test-attributes'
import { LinkType } from '../../link-without-prefetch'

type Props = {
  backgroundColor: string
  color: string
  hoverColor: string
  hoverBackgroundColor: string
  borderColor?: string
  hoverBorderColor?: string
  loaderType?: LoaderProps['type']
} & ButtonType

const BaseButton: FC<Props> = ({
  width = 'small',
  isPublic,
  isFetching,
  backgroundColor,
  color,
  hoverColor,
  hoverBackgroundColor,
  borderColor,
  hoverBorderColor,
  loaderType,
  testAttributePostfix,
  className: classNameFromProps,
  ...rest
}) => {
  const baseProps = rest as ButtonProps | LinkType
  const className = twMerge(
    twJoin(
      BASE_CLASS_NAME,
      isPublic ? PUBLIC_FONT_CLASS_NAME : DASHBOARD_FONT_CLASS_NAME,
      backgroundColor,
      color,
      hoverColor,
      hoverBackgroundColor,
      width === 'small' ? 'px-4' : 'px-10',
      borderColor && `border ${borderColor}`,
      hoverBorderColor,
      isFetching &&
        'pointer-events-none select-none text-transparent [&>*]:fill-transparent [&>*]:stroke-transparent',
    ),
    classNameFromProps,
  )

  const loaderClassName = `absolute ${
    isFetching ? 'visible' : 'invisible'
  } -translate-x-1/2 left-1/2`

  if (isLink(baseProps)) {
    const { 'aria-disabled': disabled, ...baseLinkProps } = baseProps
    return (
      <ButtonLinkWrapper
        isFetching={isFetching}
        aria-disabled={disabled || isFetching}
        className={twJoin(className, disabled && 'pointer-events-none bg-gray/50')}
        {...baseLinkProps}
        {...getDataTestAttributes(!!testAttributePostfix, `${testAttributePostfix}`)}
      >
        <>
          <Loader small type={loaderType} className={loaderClassName} />
          {baseProps.children}
        </>
      </ButtonLinkWrapper>
    )
  } else {
    const { disabled, ...baseButtonProps } = baseProps
    return (
      <button
        disabled={isFetching || disabled}
        className={twJoin(className, disabled && 'pointer-events-none bg-gray/50')}
        {...baseButtonProps}
        {...getDataTestAttributes(!!testAttributePostfix, `${testAttributePostfix}`)}
      >
        <Loader small type={loaderType} className={loaderClassName} />
        {baseProps.children}
      </button>
    )
  }
}

export default BaseButton

import React from 'react'

function CoursesIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.633"
      height="18"
      viewBox="0 0 22.633 18"
      {...props}
    >
      <g fill="CurrentColor">
        <path
          data-name="Tracé 201"
          d="m12.066.263 9.826 5.709a.576.576 0 0 1 0 1l-9.826 5.709a1.942 1.942 0 0 1-1.952 0L.288 6.968a.576.576 0 0 1 0-1L10.114.259a1.946 1.946 0 0 1 1.952 0z"
        />
        <path
          data-name="Tracé 202"
          d="m12.066.263 9.826 5.709a.576.576 0 0 1 0 1l-9.826 5.709a1.942 1.942 0 0 1-1.952 0L.288 6.968a.576.576 0 0 1 0-1L10.114.259a1.946 1.946 0 0 1 1.952 0z"
        />
        <path
          data-name="Tracé 203"
          d="m21.889 6.972-3.338 1.94-6.482 3.766a1.965 1.965 0 0 1-1.956 0L3.63 8.911.284 6.971a.583.583 0 0 1 0-1L10.112.265a1.965 1.965 0 0 1 1.956 0l9.82 5.706a.577.577 0 0 1 .001 1.001z"
        />
        <path
          data-name="Tracé 204"
          d="M12.677 13.726a3.177 3.177 0 0 1-3.168 0L3.63 10.314v3.035a1.884 1.884 0 0 0 .946 1.64l4.1 2.368a4.851 4.851 0 0 0 4.825 0l4.103-2.368a1.9 1.9 0 0 0 .946-1.641v-3.034z"
        />
        <path
          data-name="Tracé 205"
          d="M22.066 14.422V8.274l-1.456.845v5.3a1.294 1.294 0 1 0 1.456 0z"
        />
      </g>
    </svg>
  )
}

export default CoursesIcon

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import React, { useEffect, useRef, useState } from 'react'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import ChevronIcon from 'shared/icons/chevron-icon'

interface WorkspaceSelectorProps {
  className?: string
  position: 'top-menu' | 'side-menu'
}

interface WorkspaceInterface {
  id: number | null
  isWorkspace: boolean
  name: string
  avatarUrl: string
}

export function WorkspaceSelector({ position, className }: WorkspaceSelectorProps) {
  const { user } = useUser()
  const [selectedWorkspace, setSelectedWorkspace] = useState<WorkspaceInterface>()
  const [workspaces, setWorkspaces] = useState<WorkspaceInterface[]>([])

  const dropdownRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (user && isAuthorizedUser(user) && user.workspaceMemberships.length !== 0) {
      setWorkspaces([
        ...user.workspaceMemberships.map(el => ({
          isWorkspace: true,
          id: el.id,
          name: el.name,
          avatarUrl: el.image,
        })),
        {
          id: user.workspaceMemberships[0].ownerId,
          isWorkspace: false,
          name: user.subdomain,
          avatarUrl: user.avatarUrl,
        },
      ])
    }
  }, [user])

  useEffect(() => {
    if (user && isAuthorizedUser(user) && workspaces.length !== 0) {
      user.workspaceOwner
        ? setSelectedWorkspace(prev => {
            const el = user.workspaceMemberships.find(el => el.ownerId === user.workspaceOwner)
            if (el) {
              return {
                name: el.name,
                isWorkspace: true,
                id: el.id,
                avatarUrl: el.image,
              }
            }
          })
        : setSelectedWorkspace({
            name: user.subdomain,
            isWorkspace: false,
            id: user.workspaceMemberships[0].ownerId,
            avatarUrl: user.avatarUrl,
          })
    }
  }, [workspaces])

  return selectedWorkspace ? (
    <div className={`${position === 'top-menu' && 'hidden lg:flex'} ${className || ''}`}>
      <Menu>
        <div>
          <MenuButton
            onClick={() => {
              if (dropdownRef.current && buttonRef.current) {
                const buttonWidth = buttonRef.current.getBoundingClientRect().width
                const dropdownWidth = dropdownRef.current.getBoundingClientRect().width
                if (dropdownWidth < buttonWidth) {
                  dropdownRef.current.style.width = `${buttonWidth}px`
                }
              }
            }}
            className={`relative flex w-full items-center justify-between gap-3 rounded-md border border-gray/50 bg-transparent px-4 py-2 text-sm font-medium ${
              position === 'top-menu' ? 'text-gray' : 'text-darkblue'
            }`}
            as={'button'}
            ref={buttonRef}
          >
            {position === 'side-menu' && (
              <img
                className="h-7 w-7 rounded-full object-cover"
                src={selectedWorkspace.avatarUrl}
                alt={`${selectedWorkspace.name}`}
              />
            )}
            <span className="flex-1 text-start"> {selectedWorkspace.name}</span>
            <ChevronIcon
              className={`h-3 w-3 ${position === 'side-menu' && 'rotate-180'}`}
              aria-hidden="true"
            />
          </MenuButton>
        </div>
        <Transition
          as={'div'}
          className="relative"
          enter="transition duration-300 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-175 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <MenuItems
            portal={position === 'side-menu'}
            anchor={{ to: position === 'top-menu' ? 'bottom start' : 'top start', gap: 10 }}
            className={`${
              position === 'top-menu' && 'w-max'
            } divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
          >
            <div ref={dropdownRef} className="px-1 py-1">
              {workspaces.map(el => {
                if (el.id === selectedWorkspace.id) return null
                return (
                  <MenuItem key={el.id}>
                    {({ focus }) => (
                      <a
                        href={`${
                          el.isWorkspace
                            ? `/dashboard/workspace-member/switch-workspace/${el.id}`
                            : '/dashboard/workspace-member/switch-workspace'
                        }`}
                        className={`group relative flex w-full gap-3 ${
                          focus ? 'bg-blue text-white' : 'text-darkblue'
                        } items-center rounded-md px-3 py-1 text-sm`}
                      >
                        <img
                          className="h-7 w-7 rounded-full object-cover"
                          src={el.avatarUrl}
                          alt={`${el.name}`}
                        />
                        {el.name}
                      </a>
                    )}
                  </MenuItem>
                )
              })}
            </div>
          </MenuItems>
        </Transition>
      </Menu>
    </div>
  ) : null
}

import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

export function useIsActive(href: string) {
  const { asPath, isReady } = useRouter()
  const [active, setActive] = useState(false)

  useEffect(() => {
    // Check if the router fields are updated client-side
    if (isReady) {
      // Dynamic route will be matched via props.as
      // Static route will be matched via props.href
      // @ts-ignore
      const linkPathname = new URL(href, location.href).pathname

      // Using URL().pathname to get rid of query and hash
      const activePathname = new URL(asPath, location.href).pathname

      setActive(linkPathname === activePathname)
    }
  }, [asPath, isReady, href])

  return active
}

import React from 'react'

function PostsIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <g id="surface1">
        <path d="M 16.054688 0.34375 L 16.054688 3.90625 L 19.617188 3.90625 Z M 16.054688 0.34375 " />
        <path d="M 15.46875 5.078125 C 15.144531 5.078125 14.882812 4.816406 14.882812 4.492188 L 14.882812 0 L 6.484375 0 C 5.515625 0 4.726562 0.789062 4.726562 1.757812 L 4.726562 8.308594 C 4.917969 8.289062 5.113281 8.28125 5.3125 8.28125 C 7.308594 8.28125 9.097656 9.195312 10.28125 10.625 L 16.640625 10.625 C 16.964844 10.625 17.226562 10.886719 17.226562 11.210938 C 17.226562 11.535156 16.964844 11.796875 16.640625 11.796875 L 11.050781 11.796875 C 11.417969 12.511719 11.65625 13.304688 11.730469 14.140625 L 16.640625 14.140625 C 16.964844 14.140625 17.226562 14.402344 17.226562 14.726562 C 17.226562 15.050781 16.964844 15.3125 16.640625 15.3125 L 11.730469 15.3125 C 11.554688 17.246094 10.523438 18.9375 9.015625 20 L 18.203125 20 C 19.171875 20 19.960938 19.210938 19.960938 18.242188 L 19.960938 5.078125 Z M 16.640625 8.28125 L 8.046875 8.28125 C 7.722656 8.28125 7.460938 8.019531 7.460938 7.695312 C 7.460938 7.371094 7.722656 7.109375 8.046875 7.109375 L 16.640625 7.109375 C 16.964844 7.109375 17.226562 7.371094 17.226562 7.695312 C 17.226562 8.019531 16.964844 8.28125 16.640625 8.28125 Z M 16.640625 8.28125 " />
        <path d="M 5.3125 9.453125 C 2.40625 9.453125 0.0390625 11.820312 0.0390625 14.726562 C 0.0390625 17.632812 2.40625 20 5.3125 20 C 8.21875 20 10.585938 17.632812 10.585938 14.726562 C 10.585938 11.820312 8.21875 9.453125 5.3125 9.453125 Z M 6.875 15.3125 L 5.3125 15.3125 C 4.988281 15.3125 4.726562 15.050781 4.726562 14.726562 L 4.726562 12.382812 C 4.726562 12.058594 4.988281 11.796875 5.3125 11.796875 C 5.636719 11.796875 5.898438 12.058594 5.898438 12.382812 L 5.898438 14.140625 L 6.875 14.140625 C 7.199219 14.140625 7.460938 14.402344 7.460938 14.726562 C 7.460938 15.050781 7.199219 15.3125 6.875 15.3125 Z M 6.875 15.3125 " />
      </g>
    </svg>
  )
}

export default PostsIcon

import React from 'react'
import Link from 'next/link'

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  href?: undefined
  isLoading?: boolean
  width?: 'small' | 'large'
  testAttributePostfix?: string
  isNext?: boolean
}

export type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  href?: string
  isNext?: boolean
  shallow?: boolean
  isLoading?: boolean
  width?: 'small' | 'large'
}

const ButtonLinkWrapper = ({ href, isNext, ...props }: AnchorProps | ButtonProps) => {
  const { isLoading, ...rest } = props
  if (props['aria-disabled'] || isLoading) {
    return (
      <button {...(rest as ButtonProps)} disabled={props['aria-disabled'] as boolean}>
        {props.children}
      </button>
    )
  }
  return isNext ? (
    <Link href={href as string} {...(rest as AnchorProps)}>
      {props.children}
    </Link>
  ) : (
    <a href={href as string} {...(rest as AnchorProps)}>
      {props.children}
    </a>
  )
}
export default ButtonLinkWrapper

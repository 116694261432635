import { useRollbar } from '@rollbar/react'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { httpClient } from 'shared/api/http-client'
import { regenerateJWT } from 'shared/api/regenerate-jwt'
import { AccessDenied } from 'shared/errors/access-denied'
import { BadRequest } from 'shared/errors/bad-request'
import { ConflictError } from 'shared/errors/conflict-error'
import { InternalError } from 'shared/errors/internal-error'
import { PayloadTooLarge } from 'shared/errors/payload-too-large'
import { UnauthorizedResponse } from 'shared/errors/unauthorized-response'
import { UnprocessableContentError } from 'shared/errors/unprocessable-content-error'
import { ErrorResponseInterface } from 'shared/types/error-response-content-interface'
import { InvalidTokenError } from '../../errors/invalid-token-error'
import { NotFound } from '../../errors/not-found'
import { FetcherType, RequestMethodsEnum, UseApiWrapperWithErrorValidationProps } from './types'

let isJWTRegenerated = false

export const useApiWrapperWithErrorValidation = <
  M extends RequestMethodsEnum,
  R = void,
  D = any,
  BadRequestError = ErrorResponseInterface,
>({
  method,
  showFieldsErrorToast,
  badRequestHandler,
  unauthorizedResponseHandler,
  unprocessableContentHandler,
  afterFetch,
  notFoundResponseHandler,
  accessDeniedResponseHandler,
}: UseApiWrapperWithErrorValidationProps<R, M, BadRequestError>) => {
  const { t } = useTranslation()

  const router = useRouter()
  const rollbar = useRollbar()

  const [numberOfRequests, setNumberOfRequests] = useState<number>(0)
  const [isFetching, setIsFetching] = useState<boolean>(false)

  useEffect(() => setIsFetching(!!numberOfRequests), [numberOfRequests])

  const fetcher: FetcherType<M, R> = useCallback(
    async (...args) => {
      setNumberOfRequests(numberOfRequests => numberOfRequests + 1)
      return httpClient[method]<R, AxiosResponse<R>, D>(
        args[0],
        args[1] as (D & AxiosRequestConfig<D>) | undefined,
        args[2] as AxiosRequestConfig<D>,
      )
        .then(async res => res && (afterFetch ? await afterFetch(res.data) : res.data))
        .catch(async error => {
          if (typeof window !== 'undefined' && error instanceof UnauthorizedResponse) {
            if (unauthorizedResponseHandler) {
              unauthorizedResponseHandler(error)
            } else {
              window.location.assign(error.location)
            }
          } else if (error instanceof InvalidTokenError) {
            if (isJWTRegenerated) {
              toast.error(t('global.error'))
              rollbar.error('Invalid token error', error)
            } else {
              try {
                // NOTE: Generating a new JWT
                await regenerateJWT()
                isJWTRegenerated = true
                // NOTE: repeat the same request
                return fetcher(...args)
              } catch (error) {
                if (error instanceof UnauthorizedResponse) {
                  window.location.assign(error.location)
                }
              }
            }
          } else if (typeof window !== 'undefined' && error instanceof InternalError) {
            toast.error(t('core.error.internal_error_message'))
          } else if (badRequestHandler !== null && error instanceof BadRequest) {
            if (badRequestHandler) {
              badRequestHandler(error as unknown as BadRequestError)
            } else {
              const commonError = (error as ErrorResponseInterface)?.errors?.common
              if (commonError && commonError.length) {
                toast.error(commonError.join(''))
              }
            }
          } else if (error instanceof ConflictError || error instanceof UnprocessableContentError) {
            toast.error(t('core.error.conflict_error_message'))
          } else if (error instanceof PayloadTooLarge) {
            toast.error(t('core.error.payload_to_large_error_message'))
          } else if (typeof window !== 'undefined' && error instanceof AccessDenied) {
            if (accessDeniedResponseHandler) {
              accessDeniedResponseHandler(error)
              return
            }
            let searchParams = error.response?.data?.reason
              ? 'reason=' + error.response?.data?.reason
              : ''
            if (process.env.NODE_ENV === 'development') {
              router.push({
                pathname: '/403',
                search: searchParams,
              })
            } else {
              router.replace({
                pathname: '/403',
                search: searchParams,
              })
            }
          } else if (typeof window !== 'undefined' && error instanceof NotFound) {
            if (notFoundResponseHandler) {
              notFoundResponseHandler(error)
            } else {
              if (process.env.NODE_ENV === 'development') {
                router.push('/404')
              } else {
                router.replace('/404')
              }
            }
          } else if (!(error instanceof AccessDenied)) {
            toast.error(t('global.error'))
          }
          throw error
        })
        .finally(() => setNumberOfRequests(numberOfRequests => numberOfRequests - 1))
    },

    // Tfunc causes re rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      method,
      afterFetch,
      router,
      unauthorizedResponseHandler,
      showFieldsErrorToast,
      badRequestHandler,
      unprocessableContentHandler,
      notFoundResponseHandler,
      accessDeniedResponseHandler,
    ],
  ) as FetcherType<M, R>
  return { fetcher, isFetching }
}

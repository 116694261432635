import { addPublicCustomClassNamePrefix } from 'modules/course/utils/add-class-prefix'
import { menuItemsConfig } from './menu-items-config'

export function MenuSkeleton() {
  return (
    <div
      className={`flex animate-pulse items-center gap-5 xl:gap-5 ${addPublicCustomClassNamePrefix(
        'desktop-navbar-skeleton',
      )}`}
    >
      {Array.from(Array(menuItemsConfig.length).keys()).map(index => (
        <div key={index} className="h-5 w-20 rounded bg-gray" />
      ))}
    </div>
  )
}

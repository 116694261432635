import { Dispatch, MutableRefObject, SetStateAction } from 'react'
import { ParsedUrlQuery } from 'querystring'

export const setFilterQuery = <T>({
  queryRef,
  filterKeys,
  setFilter,
}: {
  queryRef: MutableRefObject<ParsedUrlQuery>
  filterKeys: MutableRefObject<(keyof T)[]>
  setFilter: Dispatch<SetStateAction<T>>
}) => {
  const newFilter = {} as T
  const query = { ...queryRef.current } as T & Record<string, string | string[] | undefined>
  filterKeys.current.forEach(el => {
    //for predefined selector
    if (query[el] === '') newFilter[el] = undefined as unknown as T[keyof T]
    if (query[el]) {
      if (!isNaN(Number(query[el]))) {
        newFilter[el] = Number(query[el]) as unknown as T[keyof T]
      } else {
        newFilter[el] = query[el]
      }
    }
  })

  setFilter(prev => ({ ...prev, ...newFilter }))
}

import React from 'react'
import { BaseButtonType } from 'shared/components/button/types/button-interface'
import LinkWithoutPrefetch, { LinkType } from '../../link-without-prefetch'

type ButtonLinkWrapperProps = LinkType & Pick<BaseButtonType, 'isFetching'>

const ButtonLinkWrapper = ({
  href,
  isFetching,
  className,
  children,
  ...baseLinkProps
}: ButtonLinkWrapperProps) => {
  if (baseLinkProps['aria-disabled'] || isFetching) {
    return (
      <button className={className} disabled={!!baseLinkProps['aria-disabled']}>
        {children}
      </button>
    )
  }
  return (
    <LinkWithoutPrefetch className={className} href={href as string} {...baseLinkProps}>
      {children}
    </LinkWithoutPrefetch>
  )
}
export default ButtonLinkWrapper

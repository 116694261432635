import React from 'react'
import ButtonLinkWrapper, { AnchorProps, ButtonProps } from 'shared/components/button-link-wrapper'
import { Loader } from './loader'

type Overload = {
  (props: ButtonProps): JSX.Element
  (props: AnchorProps): JSX.Element
}

// Guard to check if href exists in props
const hasHref = (props: ButtonProps | AnchorProps): props is AnchorProps =>
  'href' in props || 'shallow' in props

// Component
const PrimaryButton: Overload = (props: ButtonProps | AnchorProps) => {
  const { width = 'small' } = props
  const baseClassName = `min-h-[42px] relative flex items-center justify-center gap-2 rounded-lg outline-none focus-visible:ring-2 ring-blue ring-offset-1 ${
    width === 'small' ? 'px-4' : 'px-10'
  } py-2.5 font-medium text-sm hover:bg-blue-300 main-transition-colors whitespace-nowrap`
  // anchor render
  if (hasHref(props)) {
    const { isLoading, 'aria-disabled': disabled, ...rest } = props
    return (
      <ButtonLinkWrapper
        {...rest}
        isLoading={isLoading}
        aria-disabled={disabled || isLoading}
        className={`${baseClassName} ${props.className || ''} ${
          isLoading
            ? 'pointer-events-none select-none text-transparent hover:bg-blue [&>*]:fill-transparent [&>*]:stroke-transparent'
            : 'text-white'
        } ${disabled ? 'pointer-events-none bg-gray' : 'bg-blue'}`}
        href={props.href as string}
        shallow={props.shallow}
      >
        <>
          <Loader
            small
            className={`absolute ${isLoading ? 'visible' : 'invisible'} left-1/2 -translate-x-1/2`}
          />
          {props.children}
        </>
      </ButtonLinkWrapper>
    )
  }
  // button render
  const { isLoading, disabled, ...rest } = props

  return (
    <button
      {...rest}
      disabled={isLoading || disabled}
      className={` ${baseClassName} ${props.className || ''} ${disabled ? 'pointer-events-none bg-gray' : 'bg-blue'} ${
        isLoading
          ? 'pointer-events-none select-none text-transparent hover:bg-blue [&>*]:fill-transparent [&>*]:stroke-transparent'
          : 'text-white'
      } `}
    >
      <Loader
        small
        type="light"
        className={`absolute ${isLoading ? 'visible' : 'invisible'} left-1/2 -translate-x-1/2`}
      />
      {props.children}
    </button>
  )
}

export default PrimaryButton

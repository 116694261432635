import useSWR from 'swr'
import { DomainAssetsInterface } from '../types/domain-assets-interface'
import { useApiWrapperWithErrorValidation } from './use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from './use-api-wrapper-with-error-validation/types'
import useUser from './use-user'

export const DOMAIN_ASSETS_API = '/api/domain/assets'

export default function useDomainAssets() {
  const { user } = useUser()
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    DomainAssetsInterface
  >({ method: RequestMethodsEnum.get })

  const {
    data: domainAssets,
    mutate,
    isValidating,
  } = useSWR<DomainAssetsInterface>(() => user && `${DOMAIN_ASSETS_API}`, fetcher)

  return { domainAssets, mutate, isValidating }
}

import { useRouter } from 'next/router'
import useUser, { isWorkspaceMember } from 'shared/hooks/use-user'
import { useGetCoursePageUrl } from 'modules/course/hooks/use-course-path-url'

export default function useThemeEditorToggle() {
  const { user, isOwner, loading: isLoadingUserData } = useUser()

  const { query, replace } = useRouter()

  const coursePath = useGetCoursePageUrl()

  const { themeEditor, ...restParams } = query

  const isOpen = themeEditor === 'open'

  const closeEditor = () => replace({ query: { ...restParams } })

  const openEditor = () => replace({ query: { ...restParams, themeEditor: 'open' } })

  const allowedToEdit =
    isLoadingUserData || !!(user && (isOwner || isWorkspaceMember(user)) && coursePath)

  return { closeEditor, openEditor, isOpenEditor: isOpen, allowedToEdit }
}

import { useTranslation } from 'next-i18next'
import { PrimaryButton } from 'shared/components/button'
import useThemeEditorToggle from '../hooks/use-theme-editor-toggle'

export default function ThemeEditorButton({ className }: { className?: string }) {
  const { t } = useTranslation()

  const { openEditor, isOpenEditor, allowedToEdit } = useThemeEditorToggle()

  return allowedToEdit && !isOpenEditor ? (
    <PrimaryButton onClick={openEditor} className={className}>
      {t('themes.editor.cta.manage_themes')}
    </PrimaryButton>
  ) : null
}

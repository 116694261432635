import { AxiosResponse } from 'axios'
import { ErrorResponseContentInterface } from '../types/error-response-content-interface'

export class BadRequest extends Error {
  public errors: ErrorResponseContentInterface
  public response: AxiosResponse

  constructor(errors: ErrorResponseContentInterface, response: AxiosResponse) {
    super()
    this.name = this.constructor.name
    this.errors = errors
    this.response = response
  }
}

import React from 'react'
import { twMerge } from 'tailwind-merge'
import CloseIcon from 'shared/icons/close-icon'
import BaseModal, { BaseModalProps } from './base-modal'

export interface ModalProps extends BaseModalProps {
  title: string
  contentClassName?: string
  isCentered?: boolean
}

function Modal(props: React.PropsWithChildren<ModalProps>) {
  const { children, title, ...baseModalProps } = props
  return (
    <BaseModal {...baseModalProps}>
      <div
        className={`flex w-full items-start p-6 lg:p-10 lg:pb-0 ${
          props.isCentered ? 'justify-center' : 'justify-between'
        }`}
      >
        <h3 className={`${props.isCentered ? 'px-6 lg:px-10' : ''}`}>{title}</h3>
        <button
          className={`rounded-sm outline-none ring-blue ring-offset-2 focus-visible:ring-2 ${
            props.isCentered && 'absolute right-6 top-6 lg:right-10 lg:top-10'
          }`}
          disabled={baseModalProps.isFetching}
          onClick={baseModalProps.onClose}
        >
          <CloseIcon
            className={`cursor-pointer ${
              baseModalProps.isFetching && 'pointer-events-none fill-gray'
            }`}
          />
        </button>
      </div>
      <div className={twMerge('rounded-lg bg-white p-6 lg:p-10', props.contentClassName)}>
        {children}
      </div>
    </BaseModal>
  )
}

export default Modal

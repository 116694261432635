import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import ItemCount from 'shared/components/item-count'
import { useFloatHover } from 'shared/hooks/use-float-hover'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import DotsIcon from 'shared/icons/dots-icon'
import {
  ActionMenuItem,
  ActionMenuItemProps,
} from 'modules/profile/manage-suscriptions/components/table/components/action-menu-item'
import { notificationIcons } from 'modules/profile/notifications/constants/notification-icons'
import { useNotificationsActions } from 'modules/profile/notifications/hooks/use-notification-actions'
import { NotificationItemInterface } from 'modules/profile/notifications/types/notification-types'
import { dateTimeFormat } from 'modules/profile/utils/date-time-format'

export interface NotificationItemProps {
  notification: NotificationItemInterface
  actions?: ActionMenuItemProps[]
}

const NotificationItem = ({ notification, actions }: NotificationItemProps) => {
  const { user } = useUser()
  const { t } = useTranslation()
  const { id, eventType, message, read, createdAt } = notification
  const { show, delayClose, delayOpen } = useFloatHover()
  const { getNotificationLink } = useNotificationsActions()

  const Icon = notificationIcons[eventType]

  const handleNotificationClick = async () => {
    const notificationLinkTab = window.open('', '_blank')
    const { link } = await getNotificationLink(id)
    notificationLinkTab!.location.href = link
  }

  return (
    <div
      className={twMerge(
        'group flex flex-row justify-between border-b border-gray bg-white px-6 py-4 text-darkblue transition last:border-b-0 hover:cursor-pointer hover:bg-blue-100',
        read && 'text-gray group-hover:text-darkblue',
      )}
    >
      <div className="inline-flex flex-row items-center gap-4" onClick={handleNotificationClick}>
        {Icon && (
          <Icon
            className={
              'fill-gray-400 text-gray-400 transition group-hover:fill-darkblue group-hover:text-darkblue'
            }
          />
        )}
        <div className="pr-3">
          <div>
            <span
              className="transition group-hover:text-darkblue"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </div>
          {user && isAuthorizedUser(user) && (
            <div className="transition group-hover:text-darkblue">
              {dateTimeFormat(createdAt, {
                timeFormat: user.timeFormat,
                dateFormat: user.dateFormat,
                timeZone: user.timezone,
              })}
            </div>
          )}
        </div>
      </div>
      <div className="inline-flex flex-row items-center gap-4">
        {!read && <ItemCount>{t('profile.user_settings.notifications.new')}</ItemCount>}
        {actions && actions.length > 0 && (
          <Menu>
            <MenuButton
              onMouseEnter={delayOpen}
              onMouseLeave={delayClose}
              onClick={show ? delayClose : delayOpen}
              className="px-4 py-4 hover:bg-gray/30 rounded-md"
            >
              <DotsIcon />
            </MenuButton>

            <Transition
              as={Fragment}
              enter="transition duration-200 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition duration-75 ease-in"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <MenuItems
                anchor="left"
                portal
                onMouseEnter={delayOpen}
                onMouseLeave={delayClose}
                className="overflow-hidden rounded-lg border border-gray-200 bg-white shadow-lg focus:outline-none"
              >
                {actions.map(action => (
                  <ActionMenuItem
                    key={`${action.label}-na-action`}
                    label={action.label}
                    onClick={action.onClick}
                  />
                ))}
              </MenuItems>
            </Transition>
          </Menu>
        )}
      </div>
    </div>
  )
}

export default NotificationItem

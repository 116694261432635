import React from 'react'
import { Toaster } from 'react-hot-toast'
import CheckCircleIcon from 'shared/icons/check-circle-icon'
import CloseCircleIcon from 'shared/icons/close-circle-icon'

const Toast = () => {
  return (
    <Toaster
      position="top-center"
      containerStyle={{
        top: 80,
        right: 20,
      }}
      containerClassName={'text-gray-300'}
      toastOptions={{
        success: {
          icon: (
            <CheckCircleIcon className="h-[16px] w-[16px] fill-green sm:h-[20px] sm:w-[20px]" />
          ),
          className: 'border border-green [&>svg]:flex-initial [&>div]:flex-1',
          style: { color: 'currentColor' },
        },
        error: {
          icon: <CloseCircleIcon className="h-[16px] w-[16px] fill-red sm:h-[20px] sm:w-[20px]" />,
          style: { color: 'currentColor' },
          className: 'border border-red',
        },
      }}
    />
  )
}

export default Toast

import { MenuItem } from '@headlessui/react'
import React, { MouseEventHandler } from 'react'

export interface ActionMenuItemProps {
  onClick: MouseEventHandler<HTMLButtonElement>
  label: React.ReactNode
  dataTestAttributes?: Record<string, string>
}

export const ActionMenuItem = ({ dataTestAttributes, onClick, label }: ActionMenuItemProps) => (
  <MenuItem key={'action-settings'}>
    {({ focus }) => (
      <button
        onClick={onClick}
        aria-pressed={focus}
        type="button"
        className={`main-transition-colors group flex w-full items-center gap-2 border-b border-gray-200 px-4 py-3 text-left text-sm last:border-0 aria-pressed:text-blue lg:py-2`}
        {...dataTestAttributes}
      >
        <span>{label}</span>
      </button>
    )}
  </MenuItem>
)

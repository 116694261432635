import React from 'react'

function PlusIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C9.58225 16 11.129 15.5308 12.4446 14.6518C13.7602 13.7727 14.7855 12.5233 15.391 11.0615C15.9965 9.59966 16.155 7.99113 15.8463 6.43928C15.5376 4.88743 14.7757 3.46197 13.6569 2.34315C12.538 1.22433 11.1126 0.462403 9.56072 0.153721C8.00887 -0.15496 6.40034 0.00346625 4.93853 0.608967C3.47672 1.21447 2.22729 2.23985 1.34824 3.55544C0.469192 4.87103 0 6.41775 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM9 5C9 4.73479 8.89464 4.48043 8.70711 4.2929C8.51957 4.10536 8.26522 4 8 4C7.73478 4 7.48043 4.10536 7.29289 4.2929C7.10536 4.48043 7 4.73479 7 5V7H5C4.73478 7 4.48043 7.10536 4.29289 7.2929C4.10536 7.48043 4 7.73479 4 8C4 8.26522 4.10536 8.51957 4.29289 8.70711C4.48043 8.89465 4.73478 9 5 9H7V11C7 11.2652 7.10536 11.5196 7.29289 11.7071C7.48043 11.8946 7.73478 12 8 12C8.26522 12 8.51957 11.8946 8.70711 11.7071C8.89464 11.5196 9 11.2652 9 11V9H11C11.2652 9 11.5196 8.89465 11.7071 8.70711C11.8946 8.51957 12 8.26522 12 8C12 7.73479 11.8946 7.48043 11.7071 7.2929C11.5196 7.10536 11.2652 7 11 7H9V5Z"
      />
    </svg>
  )
}

export default PlusIcon

export enum LocaleEnum {
  FRENCH = 'fr',
  ENGLISH = 'en',
  SPANISH = 'es',
  ITALIAN = 'it',
  PORTUGUESE = 'pt',
  DEUTSCH = 'de',
  DUTCH = 'nl',
  RUSSIAN = 'ru',
  JAPANESE = 'jp',
  ARABIC = 'ar',
  TURKISH = 'tr',
  CHINESE = 'zh',
  SWEDISH = 'sv',
  ROMANIAN = 'ro',
  CZECH = 'cs',
  HUNGARIAN = 'hu',
  SLOVAK = 'sk',
  DANISH = 'dk',
  INDONESIAN = 'id',
  POLISH = 'pl',
  GREEK = 'el',
  SERBIAN = 'sr',
  NORWEGIAN = 'no',
  THAI = 'th',
  SLOVENIAN = 'sl',
  UKRAINIAN = 'ua',
  ALBANIA = 'sq',
  HINDI = 'hi',
}

export const localeNames: Record<LocaleEnum, string> = {
  [LocaleEnum.ENGLISH]: 'English',
  [LocaleEnum.FRENCH]: 'Français',
  [LocaleEnum.SPANISH]: 'Español',
  [LocaleEnum.ITALIAN]: 'Italiano',
  [LocaleEnum.PORTUGUESE]: 'Português',
  [LocaleEnum.DEUTSCH]: 'Deutsch',
  [LocaleEnum.DUTCH]: 'Nederlands',
  [LocaleEnum.RUSSIAN]: 'Русский',
  [LocaleEnum.JAPANESE]: '日本語',
  [LocaleEnum.ARABIC]: 'عربي',
  [LocaleEnum.TURKISH]: 'Türkçe',
  [LocaleEnum.CHINESE]: '中文(简体)',
  [LocaleEnum.SWEDISH]: 'Svenska',
  [LocaleEnum.ROMANIAN]: 'Română',
  [LocaleEnum.CZECH]: 'Čeština',
  [LocaleEnum.HUNGARIAN]: 'Magyar',
  [LocaleEnum.SLOVAK]: 'Slovenský',
  [LocaleEnum.DANISH]: 'Dansk',
  [LocaleEnum.INDONESIAN]: 'Indonesian',
  [LocaleEnum.POLISH]: 'Polski',
  [LocaleEnum.GREEK]: 'Ελληνικά',
  [LocaleEnum.SERBIAN]: 'Srpski',
  [LocaleEnum.HINDI]: 'Hindi',
  [LocaleEnum.NORWEGIAN]: 'Norsk',
  [LocaleEnum.THAI]: 'ไทย',
  [LocaleEnum.SLOVENIAN]: 'Slovenič',
  [LocaleEnum.UKRAINIAN]: 'Український',
  [LocaleEnum.ALBANIA]: 'Shqiptare',
}

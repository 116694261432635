import React from 'react'

function MembersIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="CurrentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.9963 12.4C14.1491 12.4 15.8625 10.654 15.8625 8.49999C15.8625 6.34599 14.1173 4.59999 11.9963 4.59999C9.84338 4.59999 8.13 6.34599 8.13 8.49999C8.09625 10.6525 9.84375 12.4 11.9963 12.4ZM13.8713 13.6H10.1288C7.185 13.6 4.8 15.8387 4.8 18.5987C4.8 19.1537 5.27625 19.6 5.865 19.6H18.135C18.7238 19.6 19.2 19.1537 19.2 18.5987C19.2 15.8387 16.815 13.6 13.8713 13.6ZM19.2 6.39999C20.8568 6.39999 22.2 5.05674 22.2 3.39999C22.2 1.74324 20.8575 0.399994 19.2 0.399994C17.5433 0.399994 16.2 1.74324 16.2 3.39999C16.2 5.05674 17.5425 6.39999 19.2 6.39999ZM6.89625 8.49999C6.89625 8.29566 6.93308 8.10137 6.95659 7.90337C6.5475 7.67874 6.0975 7.59999 5.62125 7.59999H3.303C1.479 7.59999 0 9.16749 0 11.0987C0 11.485 0.295763 11.8 0.66075 11.8H8.142C7.37625 10.9075 6.89625 9.76374 6.89625 8.49999ZM4.8 6.39999C6.45675 6.39999 7.8 5.05674 7.8 3.39999C7.8 1.74324 6.4575 0.399994 4.8 0.399994C3.14325 0.399994 1.8 1.74324 1.8 3.39999C1.8 5.05674 3.14325 6.39999 4.8 6.39999ZM20.6963 7.59999H18.3773C17.8973 7.59999 17.4443 7.71388 17.0325 7.90899C17.055 8.10624 17.0925 8.29749 17.0925 8.49999C17.0925 9.76412 16.6133 10.9079 15.849 11.8H23.3378C23.7038 11.8 24 11.485 24 11.0987C24 9.16749 22.5225 7.59999 20.6963 7.59999Z" />
    </svg>
  )
}

export default MembersIcon

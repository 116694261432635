import React from 'react'

function BellIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="22" height="24" viewBox="0 0 22 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M21.1719 17.2391C20.5266 16.1234 19.75 14.0016 19.75 10.25V9.47344C19.75 4.62813 15.8563 0.657819 11.0657 0.625007H11C9.85057 0.623567 8.71211 0.84891 7.64986 1.28813C6.58761 1.72735 5.62245 2.37181 4.80965 3.18461C3.99685 3.99741 3.35238 4.96258 2.91317 6.02483C2.47395 7.08707 2.2486 8.22554 2.25004 9.37501V10.25C2.25004 14.0016 1.47348 16.1234 0.828168 17.2391C0.66867 17.5047 0.583501 17.8082 0.581576 18.118C0.579652 18.4278 0.661044 18.7325 0.817231 19C0.970367 19.2675 1.19184 19.4895 1.45898 19.6432C1.72612 19.797 2.02933 19.877 2.33754 19.875H19.6625C19.9708 19.877 20.274 19.797 20.5411 19.6432C20.8082 19.4895 21.0297 19.2675 21.1829 19C21.339 18.7325 21.4204 18.4278 21.4185 18.118C21.4166 17.8082 21.3314 17.5047 21.1719 17.2391Z" />
      <path d="M14.4893 21.625H7.48926C7.25719 21.625 7.03464 21.7172 6.87054 21.8813C6.70645 22.0454 6.61426 22.2679 6.61426 22.5C6.61426 22.7321 6.70645 22.9546 6.87054 23.1187C7.03464 23.2828 7.25719 23.375 7.48926 23.375H14.4893C14.7213 23.375 14.9439 23.2828 15.108 23.1187C15.2721 22.9546 15.3643 22.7321 15.3643 22.5C15.3643 22.2679 15.2721 22.0454 15.108 21.8813C14.9439 21.7172 14.7213 21.625 14.4893 21.625Z" />
    </svg>
  )
}

export default BellIcon

import CommentIcon from 'shared/icons/comment-icon'
import CommunityIcon from 'shared/icons/community-icon'
import LikeIcon from 'shared/icons/like-icon'
import MentionIcon from 'shared/icons/mention-icon'
import { EventTypeEnum } from 'modules/profile/notifications/enums/notification-settings-enum'

export const notificationIcons: Record<EventTypeEnum, React.ElementType> = {
  [EventTypeEnum.COMMENT_ON_COMMUNITY_POST]: CommentIcon,
  [EventTypeEnum.COMMENT_REPLY_ON_COMMENT]: CommentIcon,
  [EventTypeEnum.COMMENT_ON_COURSE_CREATED_BY_ME]: CommentIcon,
  [EventTypeEnum.MENTION_IN_COMMUNITY_POST]: MentionIcon,
  [EventTypeEnum.MENTION_IN_COMMENT_ON_COMMUNITY_POST]: MentionIcon,
  [EventTypeEnum.COMMENT_IS_LIKED]: LikeIcon,
  [EventTypeEnum.COMMUNITY_NEW_PENDING_POST]: CommunityIcon,
  [EventTypeEnum.COMMUNITY_POST_IS_PUBLISHED]: CommunityIcon,
  [EventTypeEnum.COMMUNITY_POST_IS_LIKED]: LikeIcon,
}

import { DateFormatEnum, TimeFormatEnum } from 'shared/enums/date-time-format-enum'
import { UserInterface } from 'shared/types/user-interface'

export const dateTimeFormat = (
  date: string | Date,
  format: {
    dateFormat?: DateFormatEnum
    timeFormat?: TimeFormatEnum
    timeZone?: UserInterface['timezone']
  },
) => {
  const isShort =
    format.timeFormat === TimeFormatEnum.hourMinuteAmPm ||
    format.timeFormat === TimeFormatEnum.hourMinuteSecondAmPm
  const nonFormatDate = (typeof date === 'string' ? new Date(date) : date).toLocaleString(
    getFormat(format.dateFormat),
    {
      second:
        format.timeFormat === TimeFormatEnum.hourMinuteAmPm ||
        format.timeFormat === TimeFormatEnum.hourMinute
          ? undefined
          : '2-digit',
      minute: '2-digit',
      hour: '2-digit',
      day: '2-digit',
      month: '2-digit',
      hourCycle: isShort ? 'h12' : 'h23',
      year: 'numeric',
      timeZone: format.timeZone,
    },
  )
  return isShort ? nonFormatDate.replace('am', 'AM').replace('pm', 'PM') : nonFormatDate
}

export const dateFormat = (date: string | Date, format: { dateFormat?: DateFormatEnum }) =>
  (typeof date === 'string' ? new Date(date.replace(/\+(.*)/, '')) : date).toLocaleString(
    getFormat(format.dateFormat),
    {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
  )

const getFormat = (dateFormat?: DateFormatEnum): string | undefined => {
  switch (dateFormat) {
    case DateFormatEnum.dateFirst:
      return 'en-GB'
    case DateFormatEnum.monthFirst:
      return 'en-US'

    default:
      return undefined
  }
}

export const toISOStringWithoutTimezone = (date: Date) => {
  const pad = function (num: number) {
    return (num < 10 ? '0' : '') + num
  }

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds())
  )
}

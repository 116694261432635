import React from 'react'
import NotificationMenu from 'shared/components/notification-menu/notification-menu'
import useUser, { isAuthorizedUser, isCustomer } from 'shared/hooks/use-user'
import ThemeEditorButton from 'modules/theme-editor/components/theme-editor-button'
import { useAvailableSections } from '../hooks/use-available-sections'
import { isShown } from '../utils/isShown'
import { menuItemsConfig } from './menu-items-config'
import { MenuSkeleton } from './menu-skeleton'
import { SideMenuItem } from './side-menu-item'
import { TopMenuItem } from './top-menu-item'

interface MenuProps {
  isMobile?: boolean
  className: string
  menuItemClassName?: string
  menuItemActiveClassName?: string
  menuIconClassName?: string
  activeMenuIconClassName?: string
}

export function Menu({
  isMobile = false,
  className,
  menuItemClassName,
  menuItemActiveClassName,
  menuIconClassName,
  activeMenuIconClassName,
}: MenuProps) {
  const { user, loading } = useUser()
  const { data } = useAvailableSections()

  if (!user || !data || loading) {
    return <MenuSkeleton />
  }

  const MenuItem = isMobile ? SideMenuItem : TopMenuItem

  return (
    <div className={`${className}`}>
      <ThemeEditorButton className="self-center" />
      {menuItemsConfig.map((item, idx) =>
        !item.shouldItemRender || item.shouldItemRender?.(user) ? (
          <React.Fragment key={idx}>
            {data && isShown(data, item.name) ? (
              <MenuItem
                key={`menu-item-${idx}`}
                icon={item.icon}
                name={item.name}
                href={typeof item.href === 'function' ? item.href(user) : item.href}
                className={menuItemClassName}
                activeClassName={menuItemActiveClassName}
              />
            ) : null}
          </React.Fragment>
        ) : null,
      )}
      {isAuthorizedUser(user) && !isMobile && (
        <NotificationMenu
          isCustomer={isCustomer(user)}
          iconClassname={menuIconClassName}
          activeMenuIconClassname={activeMenuIconClassName}
        />
      )}
    </div>
  )
}

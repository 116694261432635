export const classWithModifiers = (
  baseClassName: string,
  modifiersObj?: Record<string, boolean>,
) => {
  return (
    baseClassName +
    (modifiersObj
      ? Object.entries(modifiersObj)
          .filter(([, shouldApply]) => !!shouldApply)
          .map(([modifier]) => ` ${baseClassName}--${modifier}`)
      : '')
  )
}

import React from 'react'

const NotificationSkeleton = () => <div className="h-[80px] w-full animate-pulse bg-gray-600" />

const NotificationsListSkeleton = () => (
  <div className="flex w-full flex-col items-center justify-center gap-3 bg-white px-6 py-4">
    <NotificationSkeleton />
    <NotificationSkeleton />
    <NotificationSkeleton />
  </div>
)

export default NotificationsListSkeleton

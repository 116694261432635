export enum OrderEnum {
  next = 'next',
  prev = 'prev',
}

export interface PaginationInterface {
  startFromId?: number | string
  order: OrderEnum
  staticUrl?: boolean
}

export type LimitType = number
export type LimitObjType = Record<'short' | 'medium' | 'long', LimitType>

export interface SwrPaginationInterface {
  limit?: LimitType
  pagination: PaginationInterface
}

export interface SwrCountInterface {
  count: number
  limitExceeded: boolean
}

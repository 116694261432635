import { isAuthorizedUser } from 'shared/hooks/use-user'
import { UnauthorizedUser, UserInterface } from 'shared/types/user-interface'

export const isAdminWorkspace = (user: UserInterface | UnauthorizedUser) => {
  if (isAuthorizedUser(user)) {
    const workspace = user.workspaceMemberships?.find(el => el.ownerId === user.workspaceOwner)
    return workspace && workspace.role === 'role_admin'
  } else {
    return false
  }
}

export const isAssistantWorkspace = (user: UserInterface | UnauthorizedUser) => {
  if (isAuthorizedUser(user)) {
    const workspace = user.workspaceMemberships?.find(el => el.ownerId === user.workspaceOwner)
    return workspace && workspace.role === 'role_assistant'
  } else {
    return false
  }
}

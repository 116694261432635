import React from 'react'
import CommunityIcon from 'shared/icons/community-icon'
import CoursesIcon from 'shared/icons/courses-icon'
import HomeIcon from 'shared/icons/home-icon'
import { UnauthorizedUser, UserInterface } from 'shared/types/user-interface'
import { getDashboardLink, getShowDashboardLink } from './utils'

export interface MenuItemConfigType {
  name: string
  href: string | ((user: UserInterface | UnauthorizedUser) => string)
  icon: JSX.Element
  shouldItemRender?: (user: UserInterface | UnauthorizedUser) => boolean
}

export const menuItemsConfig: MenuItemConfigType[] = [
  {
    name: 'header.nav.communities',
    href: '/community',
    icon: <CommunityIcon className={'scale-95'} />,
  },
  {
    name: 'header.nav.courses',
    href: '/school',
    icon: <CoursesIcon className="scale-[0.889]" />,
  },
  {
    name: 'header.nav.dashboard',
    href: user => getDashboardLink(user),
    icon: <HomeIcon className="scale-[.70]" />,
    shouldItemRender: user => getShowDashboardLink(user),
  },
]

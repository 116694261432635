import { AxiosResponse } from 'axios'

export class NotFound extends Error {
  public response: AxiosResponse

  constructor(response: AxiosResponse) {
    super()
    this.name = this.constructor.name
    this.response = response
  }
}

import { useEffect } from 'react'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import { COMMUNITY_API_PREFIX } from '../../../shared/api/constants/api-constants'
import { UnauthorizedResponse } from '../../../shared/errors/unauthorized-response'
import { useApiWrapperWithErrorValidation } from '../../../shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from '../../../shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from '../../../shared/hooks/use-user'
import { CommunityWithTopicsInterface } from '../types/community-interface'

export function useCommunityWithTopics() {
  const { user } = useUser()
  const router = useRouter()
  const { communityPath } = router.query

  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    CommunityWithTopicsInterface
  >({
    method: RequestMethodsEnum.get,
  })

  const { data, error, isValidating, mutate } = useSWR(() => {
    if (communityPath && user) {
      return `${COMMUNITY_API_PREFIX}/${communityPath}`
    }
    return null
  }, fetcher)

  useEffect(() => {
    if (error instanceof UnauthorizedResponse) {
      window.location.replace(error.location)
    }
  }, [error])

  return {
    data,
    isValidating,
    error,
    mutate,
  }
}

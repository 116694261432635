import { COMMUNITY_API_PREFIX } from '../../../shared/api/constants/api-constants'
import { httpClient } from '../../../shared/api/http-client'
import { CommunityWithTopicsInterface } from '../types/community-interface'
import { CreateTopicInterface, TopicInterface } from '../types/topic-interface'

export function createTopic(topic: CreateTopicInterface, community: CommunityWithTopicsInterface) {
  return httpClient.post<TopicInterface>(`${COMMUNITY_API_PREFIX}/${community.path}/topic`, {
    name: topic.name,
    path: topic.path,
    community: community.id,
  })
}

import { PropsWithChildren } from 'react'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import useUser from 'shared/hooks/use-user'
import { isCustomDomain } from 'shared/types/is-custom-domain'

interface LogoLinkProps extends PropsWithChildren {
  dashboardLink: string
  logoSrc: string | undefined
  shouldRenderDashboardLink: boolean
  logoUrl?: string | undefined
}
const LogoLink = ({
  children,
  dashboardLink,
  logoSrc,
  shouldRenderDashboardLink,
  logoUrl,
}: LogoLinkProps) => {
  const { user } = useUser()
  return user && logoSrc ? (
    !isCustomDomain() && shouldRenderDashboardLink ? (
      <a
        className={(!shouldRenderDashboardLink && 'pointer-events-none') || ''}
        href={dashboardLink}
      >
        {children}
      </a>
    ) : logoUrl === undefined ? (
      <img src={logoSrc} alt="Systeme.io" className="max-h-[35px]" />
    ) : (
      <LinkWithoutPrefetch href={logoUrl}>{children}</LinkWithoutPrefetch>
    )
  ) : null
}
export default LogoLink

import { MutableRefObject } from 'react'

export const getFilterQuery = <T>({
  filter,
  defaultFilter,
  filterKeys,
}: {
  filter: T
  defaultFilter: T
  filterKeys: MutableRefObject<(keyof T)[]>
}) => {
  const newFilterQuery = {} as T

  filterKeys.current.forEach(el => {
    if (filter[el] !== defaultFilter[el]) {
      newFilterQuery[el] = filter[el]
    }
  })

  //for date-range filter
  const dateSince = 'dateSince' as keyof T
  const dateSinceFilter = filterKeys.current.find(el => el === dateSince)
  const dateTill = 'dateTill' as keyof T
  const dateTillFilter = filterKeys.current.find(el => el === dateTill)
  if (
    (dateSinceFilter && filter[dateSince] !== defaultFilter[dateSince]) ||
    (dateTillFilter && filter[dateTill] !== defaultFilter[dateTill])
  ) {
    newFilterQuery[dateSince] = filter[dateSince]
    newFilterQuery[dateTill] = filter[dateTill]
  }
  return newFilterQuery
}

import React from 'react'

function CommentIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#ccd2de"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 0.25C8.3067 0.249871 6.64255 0.690745 5.17141 1.52921C3.70026 2.36768 2.47285 3.57483 1.61001 5.03182C0.747172 6.4888 0.278662 8.14538 0.250606 9.83846C0.222551 11.5315 0.635918 13.2027 1.45001 14.6875L0.653133 17.5C0.578795 17.7563 0.574616 18.0278 0.641033 18.2863C0.70745 18.5447 0.842028 18.7806 1.03072 18.9693C1.21941 19.158 1.45529 19.2926 1.71374 19.359C1.97219 19.4254 2.24373 19.4212 2.50001 19.3469L5.31251 18.55C6.6159 19.2644 8.06537 19.6713 9.55012 19.7397C11.0349 19.8081 12.5156 19.5361 13.8791 18.9445C15.2427 18.353 16.4529 17.4575 17.4174 16.3266C18.3818 15.1956 19.0749 13.8592 19.4437 12.4193C19.8125 10.9795 19.8473 9.47439 19.5453 8.01906C19.2433 6.56374 18.6126 5.19672 17.7014 4.02249C16.7901 2.84826 15.6225 1.8979 14.2877 1.24406C12.9529 0.590223 11.4863 0.250206 10 0.25V0.25Z" />
    </svg>
  )
}

export default CommentIcon

import React from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import PrimaryButton from 'shared/components/primary-button'
import { AccessDenied } from 'shared/errors/access-denied'
import { UnauthorizedResponse } from 'shared/errors/unauthorized-response'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import { useCommunityWithTopics } from '../hooks/use-community-with-topics'
import useIsUserMember from '../hooks/use-is-user-member'
import { useJoinCommunity } from '../hooks/use-join-community'
import { CommunityInterface } from '../types/community-interface'

function SidebarJoin() {
  const router = useRouter()
  const { t } = useTranslation()
  const communityPath = router.query.communityPath as CommunityInterface['path']
  const { isValidating: isCommunityLoading } = useCommunityWithTopics()
  const { mutate } = useIsUserMember()
  const { user } = useUser()
  const { joinCommunity } = useJoinCommunity()

  async function join() {
    try {
      if (user && isAuthorizedUser(user)) {
        await joinCommunity(communityPath)
        await mutate()
      } else {
        // the public community has an own login form, and we need to make custom redirection
        await router.replace(`/community/${communityPath}/join`)
      }
    } catch (e) {
      if (e instanceof UnauthorizedResponse) {
        await router.replace(`/community/${communityPath}/join`)
      } else if (e instanceof AccessDenied) {
        await router.replace(`/community/403`)
      }
    }
  }

  if (isCommunityLoading) {
    return (
      <div className="bg-white shadow-sm">
        <div className="mt-5 h-6 w-6 animate-pulse rounded-sm bg-gray" />
      </div>
    )
  }

  return (
    <div className="mb-6 flex w-full justify-center">
      <PrimaryButton onClick={join} className="w-full">
        {t('join_form.button.join.title')}
      </PrimaryButton>
    </div>
  )
}

export default SidebarJoin

import React, { FC } from 'react'
import BaseButton from 'shared/components/button/components/base-button'
import { ButtonProps } from 'shared/components/button/types/button-interface'

interface ChipProps extends ButtonProps {
  selected: boolean
}

const Chip: FC<ChipProps> = ({ selected, className, ...buttonProps }) => (
  <BaseButton
    color={selected ? 'text-blue' : 'text-darkblue'}
    hoverColor={'text-blue'}
    backgroundColor={selected ? 'bg-blue-50' : 'bg-white'}
    hoverBackgroundColor={selected ? 'bg-blue-50' : 'hover:bg-white'}
    borderColor={selected ? 'border-blue' : 'border-gray/30'}
    hoverBorderColor={'border-blue'}
    type={'button'}
    className={className + ` w-full ${selected && 'font-bold'}`}
    {...buttonProps}
  />
)

export default Chip

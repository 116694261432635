import { useRouter } from 'next/router'
import { QueryType } from 'shared/route-query/types/route-query-interface'
import { LimitType } from 'shared/types/swr-pagination-interface'

export const usePaginationQuery = <T>() => {
  const { query } = useRouter()

  const { startFromId, order, limit } = query as QueryType<T>
  return {
    startFromId: startFromId || undefined,
    order,
    limit: limit && (Number(limit) as LimitType),
  }
}

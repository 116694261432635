import { useRouter } from 'next/router'
import useSWR from 'swr'
import { useApiWrapperWithErrorValidation } from '../../../shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from '../../../shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import { AvailableSectionsInterface } from '../types/available-sections-interface'

export function useAvailableSections() {
  const { user } = useUser()
  const router = useRouter()

  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    AvailableSectionsInterface
  >({
    method: RequestMethodsEnum.get,
  })

  return useSWR(() => user && `/api/user/menu/available-sections`, fetcher, {
    onSuccess: sections => {
      if (Array.isArray(sections)) {
        router.replace('/404')
      }
    },
  })
}

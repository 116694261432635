import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import Modal from '../../../shared/components/modal'
import { BadRequest } from '../../../shared/errors/bad-request'
import { InternalError } from '../../../shared/errors/internal-error'
import PlusIcon from '../../../shared/icons/plus-icon'
import FormInput from 'shared/components/form-input'
import PrimaryButton from 'shared/components/primary-button'
import { createTopic } from '../api/topic-api'
import { generatePathFromName } from '../hooks/path-utils'
import { useCommunityWithTopics } from '../hooks/use-community-with-topics'

function AddTopic() {
  const { t } = useTranslation()
  const [opened, setOpened] = useState(false)
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [path, setPath] = useState('')
  const [pathError, setPathError] = useState('')
  const [error, setError] = useState('')
  const { data: community, mutate } = useCommunityWithTopics()

  function changeName(e: React.ChangeEvent<HTMLInputElement>) {
    setName(e.target.value)
    setNameError('')
  }

  function changePath(e: React.ChangeEvent<HTMLInputElement>) {
    setPath(e.target.value)
    setPathError('')
  }

  function convertNameToPath(e: React.FocusEvent<HTMLInputElement>) {
    if (!e.target.value) {
      setPath(generatePathFromName(name))
      setPathError('')
    }
  }

  const clearForm = () => {
    setName('')
    setPath('')
  }

  const handleSendForm = async () => {
    if (!community) {
      return
    }
    try {
      const { data } = await createTopic({ name, path }, community)
      await mutate(
        {
          ...community,
          topics: [...community.topics, data],
        },
        false,
      )
      setOpened(false)
      clearForm()
    } catch (e) {
      if (e instanceof BadRequest) {
        if (e.errors?.fields?.path) {
          setPathError(e.errors?.fields?.path.join('\n'))
        }
        if (e.errors?.fields?.name) {
          setNameError(e.errors?.fields?.name.join('\n'))
        }
        if (e.errors.common && e.errors.common.length > 0) {
          setError(e.errors.common.join('\n'))
        }
      } else if (e instanceof InternalError) {
        setError(t('core.error.internal_error_message'))
      }
    }
  }

  return (
    <>
      <div className="cursor-pointer text-darkblue">
        <PlusIcon onClick={() => setOpened(true)} />
      </div>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={t('settings.form.create_topic.title')}
      >
        <div className="mb-6">
          <FormInput
            value={name}
            label={t('settings.form.name.label')}
            onChange={changeName}
            maxLength={256}
            error={nameError}
          />
        </div>
        <div className="mb-6">
          <FormInput
            value={path}
            label={t('settings.form.path.label')}
            onChange={changePath}
            onFocus={convertNameToPath}
            maxLength={32}
            error={pathError}
          />
        </div>
        <PrimaryButton className="ml-auto" onClick={handleSendForm}>
          <PlusIcon />
          {t('settings.form.add_topic.label')}
        </PrimaryButton>
        {error && <p className="mt-2 text-sm text-red">{error}</p>}
      </Modal>
    </>
  )
}

export default AddTopic

module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: [
      'fr',
      'en',
      'es',
      'it',
      'pt',
      'de',
      'nl',
      'ru',
      'jp',
      'tr',
      'zh',
      'ar',
      'sv',
      'ro',
      'cs',
      'hu',
      'sk',
      'dk',
      'id',
      'pl',
      'el',
      'sr',
      'no',
      'th',
      'sl',
      'ua',
      'sq',
      'hi',
    ],
    localeDetection: false,
    returnEmptyString: false
  },
}

import { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { changeLanguage } from 'shared/api/user-api'
import { LocaleEnum } from 'shared/enums/locale-enum'
import useUser from 'shared/hooks/use-user'

export const useLanguageSwitcher = () => {
  const { i18n } = useTranslation()
  const { user, mutate } = useUser()
  const [isChangingLanguage, setIsChangingLanguage] = useState(false)
  useEffect(() => {
    const loadTranslations = async () => {
      if (!user) return
      const locale = user?.dashboardLocale || LocaleEnum.ENGLISH
      if (locale !== LocaleEnum.ENGLISH) {
        await i18n.changeLanguage(locale)
      }
    }
    loadTranslations()
  }, [user, i18n])

  const handleChangeLanguage = async (locale: LocaleEnum) => {
    try {
      setIsChangingLanguage(true)
      if (user) {
        await changeLanguage(locale)
      }
      await i18n.changeLanguage(locale)
      await mutate(
        () =>
          // due to fallback data in useSWR, we need to use data directly, not from mutate
          user && {
            ...user,
            dashboardLocale: locale,
          },
        false,
      )
    } catch (e) {
      console.log(e)
    } finally {
      setIsChangingLanguage(false)
    }
  }
  return { handleChangeLanguage, isChangingLanguage }
}

import React, { ReactElement, useEffect } from 'react'
import Head from 'next/head'
import { AccessDenied } from 'shared/errors/access-denied'
import useDomainAssets from 'shared/hooks/use-domain-assets'
import Sidebar from 'modules/community/components/sidebar'
import JoinCommunityProvider from 'modules/community/context/join-community-provider'
import { useCommunityWithTopics } from 'modules/community/hooks/use-community-with-topics'
import Header from 'modules/header'

const CommunityLayout = ({ children }: React.PropsWithChildren) => {
  const { data, error } = useCommunityWithTopics()
  const { domainAssets } = useDomainAssets()

  useEffect(() => {
    if (error instanceof AccessDenied) {
      window.location.replace('/community/403')
    }
  }, [error])

  return (
    <>
      <Head>
        <title>{data?.name}</title>
        {domainAssets?.faviconUrl && <link rel="icon" href={domainAssets.faviconUrl} />}
      </Head>
      <Header
        logoSrc={domainAssets?.logoUrl}
        className="fixed top-0 w-full"
        headerContainerClassName="max-w-screen-xl mx-auto"
      />
      <div className="mt-[69px]">
        <JoinCommunityProvider>
          <div className="mx-auto flex max-w-8xl gap-9 px-0 sm:px-6 md:px-14 lg:px-[6.5rem] lg:py-9">
            <Sidebar />
            <main className="w-full min-w-0">{children}</main>
          </div>
        </JoinCommunityProvider>
      </div>
    </>
  )
}

export const getCommunityLayout = (page: ReactElement) => <CommunityLayout>{page}</CommunityLayout>

export default CommunityLayout

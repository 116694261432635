import React from 'react'

function DoubleCheckmarkIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="20"
      height="10"
      viewBox="0 0 20 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2254 0.899587C12.5915 1.2657 12.5915 1.8593 12.2254 2.22541L5.35041 9.10041C4.9843 9.46653 4.3907 9.46653 4.02459 9.10041L0.587087 5.66291C0.220971 5.2968 0.220971 4.7032 0.587087 4.33709C0.953204 3.97097 1.5468 3.97097 1.91291 4.33709L4.6875 7.11167L10.8996 0.899587C11.2657 0.533471 11.8593 0.533471 12.2254 0.899587Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4129 0.899587C19.779 1.2657 19.779 1.8593 19.4129 2.22541L12.5379 9.10041C12.1718 9.46653 11.5782 9.46653 11.2121 9.10041L9.38396 7.27229C9.01785 6.90617 9.01785 6.31258 9.38396 5.94646C9.75008 5.58035 10.3437 5.58035 10.7098 5.94646L11.875 7.11167L18.0871 0.899587C18.4532 0.533471 19.0468 0.533471 19.4129 0.899587Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default DoubleCheckmarkIcon

import { useRouter } from 'next/router'
import useSWR from 'swr'
import { COMMUNITY_API_PREFIX } from '../../../shared/api/constants/api-constants'
import { useApiWrapperWithErrorValidation } from '../../../shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from '../../../shared/hooks/use-api-wrapper-with-error-validation/types'

export default function useMembersCount() {
  const router = useRouter()
  const { communityPath } = router.query
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, number>({
    method: RequestMethodsEnum.get,
  })

  const { isValidating: isLoading, data: membersCount } = useSWR(() => {
    return `${COMMUNITY_API_PREFIX}/${communityPath}/members/count`
  }, fetcher)

  return { membersCount, isLoading }
}

import React from 'react'

function CheckCircleIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      //   fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="#CBD5E1"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.084 10.042C20.084 15.588 15.588 20.084 10.042 20.084C4.49594 20.084 0 15.588 0 10.042C0 4.49594 4.49594 0 10.042 0C15.588 0 20.084 4.49594 20.084 10.042ZM8.88044 15.3591L16.3309 7.90864C16.5839 7.65565 16.5839 7.24542 16.3309 6.99243L15.4147 6.07622C15.1618 5.82319 14.7515 5.82319 14.4985 6.07622L8.42231 12.1523L5.58549 9.31553C5.3325 9.06254 4.92227 9.06254 4.66924 9.31553L3.75303 10.2317C3.50004 10.4847 3.50004 10.895 3.75303 11.1479L7.96419 15.3591C8.21722 15.6121 8.62741 15.6121 8.88044 15.3591Z"
      />
    </svg>
  )
}

export default CheckCircleIcon

import React from 'react'
import { twMerge } from 'tailwind-merge'

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string | JSX.Element
  error?: string
  inputClassName?: string
}

function FormInput({
  label,
  value,
  onChange,
  onFocus,
  maxLength,
  error,
  placeholder,
  required,
  className,
  inputClassName,
  ...rest
}: InputProps) {
  return (
    <label className={`flex flex-col gap-1 text-sm font-medium text-darkblue ${className || ''}`}>
      {(label || required) && (
        <div className="flex gap-0.5">
          {label && <span>{label}</span>}
          {required && <span className="text-red">*</span>}
        </div>
      )}
      <input
        className={twMerge(
          'mt-1 w-full rounded-lg border border-gray px-4 py-2.5 focus:border-gray focus:outline-none',
          inputClassName,
        )}
        type={rest.type || 'text'}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        maxLength={maxLength}
        placeholder={placeholder}
        spellCheck={false}
        disabled={rest.disabled}
      />
      {error && <p className="mt-2 text-sm text-red">{error}</p>}
    </label>
  )
}

export default FormInput

import React, { createContext, useContext, useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import StickyJoin from '../components/sticky-join'
import { useCommunityWithTopics } from '../hooks/use-community-with-topics'
import useIsUserMember from '../hooks/use-is-user-member'

export const hasCommunityJoinCookie = (communityId: number) =>
  Boolean(Cookies.get(`community_join_${communityId}`))

export const setCommunityJoinCookie = (communityId: number) =>
  Cookies.set(`community_join_${communityId}`, '1', { expires: 30 })

const JoinCommunityContext = createContext<boolean>(false)

export const useNeedShowJoin = () => useContext(JoinCommunityContext)

function JoinCommunityProvider({ children }: React.PropsWithChildren<any>) {
  const { isUserMember } = useIsUserMember()
  const { data: community } = useCommunityWithTopics()
  const [needShowJoin, setNeedShowJoin] = useState(false)

  useEffect(() => {
    if (community && !community.private) {
      if (isUserMember === false && !hasCommunityJoinCookie(community.id)) {
        setNeedShowJoin(true)
      } else if (isUserMember === true && needShowJoin) {
        setNeedShowJoin(false)
      }
    }
  }, [community, isUserMember])

  function closeJoin() {
    if (community) {
      setNeedShowJoin(false)
      setCommunityJoinCookie(community.id)
    }
  }

  return (
    <JoinCommunityContext.Provider value={needShowJoin}>
      {needShowJoin && <StickyJoin closeJoin={closeJoin} />}
      {children}
    </JoinCommunityContext.Provider>
  )
}

export default JoinCommunityProvider

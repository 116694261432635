import React from 'react'

function ChevronIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="9"
      height="5"
      viewBox="0 0 9 5"
      fill="CurrentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.54022 0.210728C8.297 -0.0324822 7.90225 -0.0324822 7.65841 0.210728L4.79847 3.07067C4.55214 3.30702 4.163 3.30702 3.91667 3.07067L1.05673 0.210728C0.809153 -0.0287422 0.414402 -0.0218822 0.174922 0.226318C-0.0583075 0.467658 -0.0583075 0.850568 0.174922 1.09253L3.03424 3.95247C3.7645 4.68336 4.94939 4.68336 5.68028 3.95247V3.95247L8.54022 1.09253C8.78343 0.848688 8.78343 0.453938 8.54022 0.210718V0.210728Z"
      />
    </svg>
  )
}

export default ChevronIcon
